//
// echarts.scss
//

.e-charts {
  height: 350px;
}

.chartjs-chart {
  max-height: 320px;
}
