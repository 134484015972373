//
// alertify.scss
//

.alertify {
  .ajs-body {
    .ajs-content {
      .ajs-input {
        color: var(--#{$prefix}gray-600);
        background-color: var(--#{$prefix}secondary-bg);
        border: 1px solid var(--#{$prefix}gray-300);
      }
    }
  }
}

.alertify {
  .ajs-footer {
    .ajs-buttons {
      .ajs-button {
        color: var(--#{$prefix}gray-500);
      }
    }
  }
}

.alertify {
  .ajs-header {
    font-weight: $font-weight-medium;
    background-color: var(--#{$prefix}secondary-bg);
  }
  .ajs-body {
    color: var(--#{$prefix}gary-600);
  }
  .ajs-dialog {
    box-shadow: $box-shadow;
    background-color: var(--#{$prefix}secondary-bg);

    .ajs-header,
    .ajs-footer {
      background-color: var(--#{$prefix}secondary-bg);
      color: var(--#{$prefix}gary-600);
      border-bottom: var(--#{$prefix}border-color) 1px solid;
      border-top: var(--#{$prefix}border-color) 1px solid;
    }
  }

  .ajs-footer {
    background-color: var(--#{$prefix}secondary-bg);
    .ajs-buttons {
      .ajs-button {
        font-weight: $font-weight-medium;
        &.ajs-ok {
          color: $primary;
        }
      }

      &.ajs-primary {
        text-align: right;
      }
    }
  }

  .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: none;
  }

  .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px;

    .ajs-header {
      background-color: var(--#{$prefix}secondary-bg);
    }
  }
}

.alertify-notifier {
  .ajs-message {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    text-shadow: none !important;
    &.ajs-success {
      background-color: $success;
      border-color: $success;
    }
    &.ajs-error {
      background-color: $danger;
      border-color: $danger;
    }
    &.ajs-warning {
      background-color: $warning;
      border-color: $warning;
    }
  }

  &.ajs-right {
    right: 10px;
    left: auto;
    .ajs-message {
      right: -320px;
      left: auto;
      &.ajs-visible {
        right: 290px;
        left: auto;
      }
    }
  }

  &.ajs-left {
    left: 10px;
    right: auto;
    .ajs-message {
      left: -300px;
      right: auto;
      &.ajs-visible {
        left: 0;
        right: auto;
      }
    }
  }
}

// [data-bs-theme="dark"]{
//     .alertify{
//         .ajs-header{
//             background-color: var(--#{$prefix}body-bg) !important;
//         }
//     }
// }
