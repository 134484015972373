/* Pagination Styles */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 3px;
}

.pagination-btn:hover {
  background-color: #0056b3;
}

/* ----------------------------global filter---------------------- */
/* search input field container */
.search-box {
  position: relative;
  /* top: 50px; */
  width: 100%;
  margin: auto;
}

.search-box i {
  position: absolute;
  left: 12px;
  top: 12px;
}

/* controls the all type search input fields */
input[type="search"] {
  display: inline-block;
  background-color: rgba(211, 211, 211, 0.714);
  width: 100%;
  font-size: 1.5rem;

  padding-left: 2.5rem;
  padding-right: 1rem;
  padding-bottom: 0.2rem;

  outline: none;
  border: none;
  border-radius: 10px;
}

/* ------------------global filter end------------------------ */

.filterhover:hover {
  background-color: rgba(237, 235, 235, 0.933);
}

th {
  background-color: #5156be !important;
}
