//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}light-dark);
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}light-dark);
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 1px solid var(--#{$prefix}gray-300);

  // .nav-item {
  //   position: relative;
  //   color: var(--#{$prefix}light-dark);

  //   .nav-item {
  //     position: relative;
  //     color: var(--#{$prefix}light-dark);
  //     text-transform: uppercase;

  //     .nav-link {
  //       border: none;
  //       padding: 5px 10px 5px 10px;
  //       margin-right: 10px;

  //       &::after {
  //         content: "";
  //         background: $primary;
  //         height: 1px;
  //         position: absolute;
  //         width: 100%;
  //         left: 0;
  //         bottom: -1px;
  //         transition: all 250ms ease 0s;
  //         transform: scale(0);
  //       }

  //       &.active {
  //         color: $primary;

  //         &:after {
  //           transform: scale(1);
  //         }
  //       }
  //     }
//   }
  // }
  .nav-item {
    position: relative;
    color: var(--#{$prefix}light-dark);
    text-transform: uppercase;

    .nav-link {
        border: none;

        &::after {
            content: "";
            background: $primary;
            height: 1px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -1px;
            transition: all 250ms ease 0s;
            transform: scale(0);
        }

        &.active {
            color: $primary;

            &:after {
                transform: scale(1);
            }
        }

    }
}


  &.card-header-tabs {
    border-bottom: none;

    .nav-link {
      padding: $card-cap-padding-y $nav-link-padding-x;
      font-weight: $font-weight-medium;
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
